import React, { useMemo, useState, useRef, useEffect} from 'react';

import mapboxgl from '!mapbox-gl';  // eslint-disable-line import/no-webpack-loader-syntax

import LegendControl from 'mapboxgl-legend';
import 'mapboxgl-legend/dist/style.css';

import CLAttributionControl from 'map/attribution_control.js'
import CLGeolocateControl from 'map/geolocate_control.js'
import MapNavbar from 'map/components/map_navbar.js'
import {addCrownLandLayers} from 'map/layers.js'
import {addLayerSources} from 'map/layers.js'

import layer_info from 'data/layer_info.json'

import 'bootstrap/dist/css/bootstrap.min.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN


export default function App() {

  const defaultProvChecked = "on"
  const [provChecked, setProvChecked] = useState(defaultProvChecked);
  function handleProvChecked(e) {
    setProvChecked(e.target.value)
  }

  const mapContainer = useRef(null);
  const map = useRef(null);

  var legend = useMemo(
    () => new LegendControl(
      {
        toggler: true,
      }
    ),
    []
  );

  // Initial map values.
  // Keep track of bounds as we navigate the map.
  /*
   * Initialize map.
   */
  useEffect(() => {
    if (map.current) {
      // Initialize map only once.
      return
    }

    // Map defaults.
    // Zoom in on
    map.current = new mapboxgl.Map({
      attributionControl: false,  // is set later
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/outdoors-v11?optimize=true',
      center: layer_info[defaultProvChecked].center,
      zoom: layer_info[defaultProvChecked].zoom
    });

    // Add legend, attribution, nav, and geolocate controls.
    map.current.addControl(legend, 'bottom-left');
    map.current.addControl(new CLAttributionControl(), 'bottom-right');
    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');
    map.current.addControl(new CLGeolocateControl(), 'top-right');
  });


  useEffect(() => {
    // Handle the province toggle.

    // Add the initial layer on first load.
    map.current.on('load', () => {
      addLayerSources(map.current)
      addCrownLandLayers(map.current, provChecked, legend)
    });
    // Otherwise add the layer
    // TODO this makes it so that nothing happens if you click
    // before things are loaded. Should try to fix that so
    // the event is "queued".
    if (map.current.loaded()) {
      addCrownLandLayers(map.current, provChecked, legend)
    }
    map.current.resize();

  }, [provChecked, legend]);

  return (
    <div>
      <MapNavbar
        defaultProvChecked={defaultProvChecked}
        provChecked={provChecked}
        handleProvChecked={handleProvChecked}
      />
      <div>
        <div ref={mapContainer} className="map-container" />
      </div> 
    </div>
  );
}
