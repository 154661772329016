import ValueWithInfoLink from 'map/components/value_with_info_link'

import holder_codes from 'data/nb/holder_codes.json'


const LAYER_ID_TO_CUSTOM_FIELDS = {
  'bc_crown_land': get_custom_fields_bc_crown_land,
  'nb_crown_land': get_custom_fields_nb_crown_land,
  'on_general_use_areas': get_custom_fields_on,
  'on_conservation_reserves': get_custom_fields_on,
  'on_enhanced_management_areas': get_custom_fields_on,
  'on_forest_reserves': get_custom_fields_on,
  'on_protected_areas_far_north': get_custom_fields_on,
  'on_provincial_parks': get_custom_fields_on,
  'on_provincial_wildlife_areas': get_custom_fields_on,
  'on_rec_conservation_reserves': get_custom_fields_on,
  'on_rec_provincial_parks': get_custom_fields_on,
  'on_wilderness_areas': get_custom_fields_on,
}


export default function get_custom_fields(layer_id, properties) {
  /*
   * Return custom fields for the area popup. This function determines
   * if custom fields are needed for this layer, then delegates
   * to a more specific function for calculations.
   *
   * Parameters
   * ----------
   * layer_id:
   *   The layer ID
   * properties:
   *   An object with key-value pairs of layer properties, from the mouse click event.
   *
   * Returns
   * -------
   * Array
   *   An array of key-value pairs to render as a table row in the area popup.
   */
  if (layer_id in LAYER_ID_TO_CUSTOM_FIELDS) {
    return LAYER_ID_TO_CUSTOM_FIELDS[layer_id](properties)
  }
  return []
}


function get_custom_fields_nb_crown_land(properties) {
  /*
   * Custom NB fields
   */
  // Build the holder field
  // Get associated holder description and build target link using code.
  var holder_obj = holder_codes.find(
      item => String(item.code) === String(properties.holder)
  )
  var info_link = "/layer-help#nb-holder-code-" + properties.holder

  var holder_field = (
    <ValueWithInfoLink
      value={holder_obj.description}
      href={info_link}
    />
  );

  return [["Management Info", holder_field]]
}


function get_custom_fields_bc_crown_land(properties) {
  // Add info link to "Owner Type" field
  const owner_field = (
    <ValueWithInfoLink
      value={properties.OWNER_TYPE}
      href="http://help.ltsa.ca/parcelmap-bc/owner-types-parcelmap-bc"
    />
  );
  // Add an "Additional Info" field.
  const additional_info = (
    <a
      href="https://catalogue.data.gov.bc.ca/dataset/parcelmap-bc-parcel-fabric#object-description"
      target="_blank"
      rel="noreferrer"
    >
      Field Descriptions
    </a>
  )
  return [
    ["Parcel Owner", owner_field],
    ["Additional Info", additional_info]
  ]
}

function get_custom_fields_on(properties) {
  // Add info link to "Land Use Policy" field
  var land_use_link = "https://www.lioapplications.lrc.gov.on.ca/services/CLUPA/xmlReader.aspx?xsl=web-primary.xsl&type=primary&POLICY_IDENT=" + properties.POL_IDENT
  var id_str = "ID: " + properties.POL_IDENT
  const land_use_field = (
    <div>
      {id_str}
      <br/>
      <a
        href={land_use_link}
        target="_blank"
        rel="noreferrer"
      >
        View Report
      </a>
    </div>
  );

  return [
    ["Land Use Policy", land_use_field],
  ]
}
