import AreaPopup from './area_popup'
import layer_info from 'data/layer_info.json'
import color_palette from 'data/color_palette.json'


function addLayerSources(map) {
  /* Add the layer sources to the map.
   * Expects a tileset to exist in mapbox
   * with a name of the form pe_combined_res13
   * for each province in layer_info.province_codes
   *
   * @param {mapboxgl.Map} map: the map to add layer sources to
   */
  layer_info.province_codes.forEach((prov_code) => {
    var layers = layer_info[prov_code].layers
    layers.forEach(function(active_layer, i) {
      // The tileset could already exist, so check that first.
      var existing_source = map.getSource(active_layer.tileset_name)
      if (!existing_source) {
        map.addSource(active_layer.tileset_name, {
          type: 'vector',
          url: 'mapbox://mghughes91.' + active_layer.tileset_name
        });
      }
    });
  });

}


function addCrownLandLayers(map, prov_code, legend) {
  /* Add crown land layers to the map.
   * Expects a tileset to exist in mapbox
   * with a name of the form ns_crown_land
   * for each province in layer_info.province_codes
   *
   * @param {mapboxgl.Map} map: the map to add layers to
   * @param {string} prov_code: The province code of the layer to add.
   * @param {LegendControl} legend: The legend control for the map.
   */


  // Remove all old layers
  layer_info.province_codes.forEach((active_prov) => {
    var layers = layer_info[active_prov].layers
    layers.forEach((active_layer) => {
      var layer_id = active_layer.source_name
      if (map.getLayer(layer_id)) {
        map.removeLayer(layer_id)
        // Remove layer from legend too.
        legend.removeLayers([layer_id])
      }
    });
  });


  // Add back the ones we want.
  var layers = layer_info[prov_code].layers
  for (let i = layers.length - 1; i >= 0; i--) {
    var active_layer = layers[i]
    var layer_name = active_layer.layer_name
    var layer_id = active_layer.source_name
    var layer_color = color_palette.layer_colors[i].fill

    map.addLayer({
      'id': layer_id,
      'type': 'fill',
      'source': active_layer.tileset_name,
      'source-layer': layer_id,
      'minzoom': 1,
      // TODO
      'paint': {
        'fill-color': layer_color,
        'fill-opacity': 0.5
      },
      // For the legend labels.
      'metadata': {
        'name': layer_name.replace('-', ' '),
        'labels': {
          [layer_color]: layer_name.replace('-', ' ')
        }
      }

    });

    // Add layer to the legend.
    legend.addLayers([layer_id])

    // Add popup when clicking on the layer
    // and change mouse cursor appropriately.
    map.on('click', layer_id, (e) => {
      new AreaPopup(e).addTo(map)
    });
    map.on('mouseenter', layer_id, () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', layer_id, () => {
      map.getCanvas().style.cursor = '';
    });
  };


  // Zoom in on the layer.
  map.flyTo({
    zoom: layer_info[prov_code].zoom,
    center: layer_info[prov_code].center,
    speed: 1.0
  });

}

export {addCrownLandLayers, addLayerSources }
